<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <listitemspages
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'groups'"
          :AllResponse="'allgroups'"
          :qCreate="Qcreate"
          :qUpdate="Qupdate"
          :Get_suf="'Group'"
          :title="'Groupes'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Groups'"
          :sheetname="'Group'"
          :key="klist"
          :showstd="true"
          @rowselect="GroupChange"
          :Add="true"
          :Update="true"
          :del="group.create_uid == $store.state.me.id"
          :del_disable="group.nbmembers > 0"
          :laoding="loading"
          @change="refresh"
          @add="refresh"
          @delete="refresh"
          :list_options="false"
          :WhereExt="WhereExt"
        >
        </listitemspages>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="group.id">
        <listitems
          :list="members_list"
          :qCreate="Qmembercreate"
          :qUpdate="Qmemberupdate"
          :qDelete="Qmemberdelete"
          :Get_suf="'GroupMember'"
          :title="'Membres du Groupe : ' + group.name"
          :headers="members_headers"
          :key="km"
          :master="true"
          :showstd="true"
          :add="group.create_uid == $store.state.me.id"
          :add_disable="group.id != null"
          :del="group.create_uid == $store.state.me.id"
          :Update="true"
          :filename="'Members'"
          :sheetname="'Members'"
          :push="true"
          @change="refresh"
          @add="refresh"
          @delete="refresh"
        >
        </listitems>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CREATE_GROUP from "../graphql/File/CREATE_GROUP.gql";
import UPDATE_GROUP from "../graphql/File/UPDATE_GROUP.gql";
import DELETE_GROUP from "../graphql/File/DELETE_GROUP.gql";
import CREATE_GROUP_MEMBER from "../graphql/File/CREATE_GROUP_MEMBER.gql";
import UPDATE_GROUP_MEMBER from "../graphql/File/UPDATE_GROUP_MEMBER.gql";
import DELETE_GROUP_MEMBER from "../graphql/File/DELETE_GROUP_MEMBER.gql";

import GROUPS from "../graphql/File/GROUPS.gql";
import ALLGROUPS from "../graphql/File/ALLGROUPS.gql";
import USERS from "../graphql/Users/USERS.gql";

export default {
  components: {
    listitemspages: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    loading: false,
    klist: 400,
    km: 500,
    group: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    selitem: {},
    WhereExt: [{ column: "ID", operator: "NEQ", value: -1 }],
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Nom",
        value: "name",
        enum: "NAME",
        rules: [(v) => !!v || "Nom obligatoire"],
        slot: "href",
        edit: true,
        type: "text",
        sm: 9,
        md: 9,
        selected: true,
      },
      {
        text: "Date creation",
        value: "created_at",
        enum: "CREATED_AT",
        slot: "date",
        selected: true,
      },
      {
        text: "Type",
        value: "type",
        enum: "TYPE",
        default: "User",
        type: "select",
        edit: true,
        rules: [(v) => !!v || "Type obligatoire"],
        select_list: ["User", "System"],
        show_list: ["User", "System"],
        selected: true,
        sm: 3,
        md: 3,
      },
      {
        text: "Propriétaire",
        value: "owner",
        enum: "OWNER",
        hiden: true,
        selected: true,
      },
      {
        text: "Membres",
        value: "nbmembers",
        enum: "NBMEMBERS",
        selected: true,
      },
      {
        text: "Couleur",
        value: "color",
        enum: "COLOR",
        type: "color",
        edit: true,
        selected: false,
        sm: 3,
        md: 3,
      },
    ],
    members_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Matricule",
        value: "matricule",
        selected: true,
      },
      {
        text: "Utilisateur",
        value: "user_id",
        selected: true,
        slot: "simple-select",
        edit: true,
        type: "select",
        rules: [(v) => !!v || "Utilisateur obligatoire"],
        select_list: [],
        show_list: [],
        item_value: "id",
        item_text: "name",
        sm: 6,
        md: 6,
      },
      {
        text: "group_id",
        value: "group_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    users_list: [],
  }),
  watch: {},
  computed: {
    Qcreate() {
      return CREATE_GROUP;
    },
    Qupdate() {
      return UPDATE_GROUP;
    },
    Qdelete() {
      return DELETE_GROUP;
    },
    Qselect() {
      return GROUPS;
    },
    Qselectall() {
      return ALLGROUPS;
    },
    Qmembercreate() {
      return CREATE_GROUP_MEMBER;
    },
    Qmemberupdate() {
      return UPDATE_GROUP_MEMBER;
    },
    Qmemberdelete() {
      return DELETE_GROUP_MEMBER;
    },
    members_list() {
      return this.group.members ? this.group.members : [];
    },
  },
  created() {},

  mounted() {
    this.headers[3].type = this.$store.state.isadmin ? "select" : null;
    this.headers[3].default = this.$store.state.isadmin ? null : "User";
    this.headers[4].hiden = !this.$store.state.isadmin;

    this.get_data();
  },

  methods: {
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    refresh() {
      this.klist++;
      if (this.group.members)
        this.members_headers[2].select_list = this.users_list.filter(
          (elm) =>
            !this.group.members.map((elm) => elm.user_id).includes(elm.id)
        );
    },
    async get_data() {
      this.loading = true;
      await this.$apollo
        .query({
          query: USERS,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.users_list = data.data.users.filter((elm) => elm.id != 1);
          this.members_headers[2].show_list = this.users_list;
          this.loading = false;
        });
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async GroupChange(item) {
      this.group = item;
      this.selitem = item;
      if (this.group.members)
        this.members_headers[2].select_list = this.users_list.filter(
          (elm) =>
            !this.group.members.map((elm) => elm.user_id).includes(elm.id)
        );
      this.members_headers[3].default = this.group.id;
      this.km++;
    },
  },
};
</script>
